import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';
import Img from "gatsby-image"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import Layout from '../components/Layout'
import Slider from '../components/Slider';
import Features from '../components/Feartures';

const containerStyle = {
  width: 'auto',
  height: '100%'
};
const icon = {
  url: "/img/logMap.png",
  scaledSize: {width: 50, height: 50}
}
export const IndexPageTemplate = ({
  title,
  carouselMenu,
  menuText,
  carouselS,
  apartamentosText,
  carouselT,
  featuresOne,
  carouselF,
  featuresTwo,
  titleLeft,
  titleRight,
  featuresThree,
  percentages,
  nearPlaces,
  mapPoint,
  bottomTitle,
  bottomText,
  staticMap
}) => (
  <div>
    <section id="home">
      <Row>
        <Col md={2} xs={12} className="sideBar" >
          <a href='/'><img src="/img/CDV.png" alt="caminoalcedrovi" /></a>
        </Col>
        <Col>
            <Slider items={carouselMenu} />
            <h2>VIA AL <div>CEDRO</div> VI</h2>
            <div style={{color: 'white', margin: '5vh 5vw', textAlign: 'left', fontSize: '1.25rem', whiteSpace: 'pre-wrap' }}>{menuText}</div>
        </Col>
      </Row>
    </section>
    <section id="secondSlider">
      <Row>
        <Col className="apartamentos">
          <Slider items={carouselS} />
          <div className="apartamentosText"><h3>{"APARTAMENTOS"}</h3><p>{apartamentosText}</p></div>
        </Col>
      </Row>
    </section>
    <section id="sectionVista" className="backgroundSectionGray">
      <Row>
        <Col md="2" xs="0">
          <Container>
            <Row>
              <Col>

              </Col>
              <Col>

              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Slider items={carouselT} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Features items={featuresOne} />
        </Col>
      </Row>
    </section>
    <section id="sectionLobby" className="backgroundSectionGreen">
      <Row>
        <Col>
          <Slider items={carouselF} />
        </Col>
        <Col md="2" xs="0">
          <Container>
            <Row>
              <Col>

              </Col>
              <Col>

              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <Features items={featuresTwo} />
        </Col>
      </Row>
    </section>
    <section id="dinero" className="backgroundSectionGray">
      <Container>
        <Row>
          <Col>
            <h2>SU <div>DINERO</div> ESTÁ ASEGURADO</h2>
            <Features items={featuresThree} inverted={true} />
          </Col>
          <Col md="8" xs="12" lg={6} className="percentages">
            <h2>ADQUIERA SU <div>APARTAMENTO</div> PAGANDOLO A CUOTAS</h2>
            {percentages.map(percentage => {
              return (
                <Row>
                  <Col md="4" xs="4"><h2>{percentage.number}</h2></Col>
                  <Col md="8" xs="8" ><h6>{percentage.description}</h6></Col>
                </Row>
              )
            })}
          </Col>
        </Row>
      </Container>
    </section>
    <section id="location" className="backgroundSectionGray">
      <Container fluid="lg">
        <div className="title"><h1>Ubicación</h1></div>
        <Row>
          <Col md="5" xs="12" className="places">
            {nearPlaces.map(place => {
              return (
                <Row>
                  <Col md={2}><img src={place.image} /></Col>
                  <Col md={9} className="info">
                    <p>{place.title}</p>
                    <div className="times">
                    {place.times.map(time => {
                      return(
                        <div className="time"><span className={'fa '+time.icon}/>{time.time}</div>
                      )
                    })}</div></Col>
                </Row>
              )
            })}
          </Col>
          <Col md="6" xs="12" className="map">
            <LoadScript googleMapsApiKey="AIzaSyDxrWLrtZ1y_pHOyOZpfIPVmxI5DcMm-X8">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapPoint}
                zoom={17}>
                  <Marker 
                    position={mapPoint} 
                    icon={icon} 
                    />
              </GoogleMap>
              {/* <Img fluid={staticMap} alt={mapPoint} /> */}
            </LoadScript> 
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{bottomTitle}</h2>
          </Col>
          <Col>
            <p>{bottomText}</p>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {

  title: PropTypes.string,
  carouselMenu: PropTypes.array,
  menuText: PropTypes.string,
  carouselS: PropTypes.array,
  apartamentosText: PropTypes.string,
  carouselT: PropTypes.array,
  featuresOne: PropTypes.array,
  carouselF: PropTypes.array,
  featuresTwo: PropTypes.any,
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
  featuresThree: PropTypes.array,
  percentages: PropTypes.array,
  nearPlaces: PropTypes.array,
  mapPoint: PropTypes.string,
  bottomTitle: PropTypes.string,
  bottomText: PropTypes.string,
  staticMap: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  //const image = data.staticMap.childFile.childImageSharp.fluid
  const point = JSON.parse(frontmatter.map_point)
  const center = {lng: point.coordinates[0], lat: point.coordinates[1]}
  return (
    <Layout title="Home" description={frontmatter.description}>
      <IndexPageTemplate
        title={frontmatter.title}
        carouselMenu={frontmatter.carousel_menu}
        menuText={frontmatter.menu_text}
        carouselS={frontmatter.carousel_sec}
        apartamentosText={frontmatter.apartamentos_text}
        carouselT={frontmatter.carousel_thr}
        featuresOne={frontmatter.features_one}
        carouselF={frontmatter.carousel_fo}
        featuresTwo={frontmatter.features_two}
        titleLeft={frontmatter.title_left}
        titleRight={frontmatter.title_right}
        featuresThree={frontmatter.features_three}
        percentages={frontmatter.percentages}
        nearPlaces={frontmatter.near_places}
        mapPoint={center}
        bottomTitle={frontmatter.bottom_title}
        bottomText={frontmatter.bottom_text}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    staticMap: PropTypes.object,
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        carousel_menu {
          item_image
          item_header
          item_caption
          item_alt_text
        }
        menu_text
        carousel_sec {
          item_image
          item_header
          item_caption
          item_alt_text
        }
        apartamentos_text
        carousel_thr {
          item_image
          item_header
          item_caption
          item_alt_text
        }
        features_one {
          feature_image
          feature_header
        }
        carousel_fo {
          item_image
          item_header
          item_caption
          item_alt_text
        }
        features_two {
          feature_image
          feature_header
        }
        title_left
        features_three {
          feature_image
          feature_header
        }
        title_right
        percentages {
          number
          description
        }
        near_places {
          image
          title
          times {
            icon
            time
          }
        }
        map_point
        bottom_title
        bottom_text
      }
    }
  }
`